<template>
                <p><strong>如何渲染体积云</strong></p>
                <p>&emsp;  目前Blender自动打包不支持把vdb文件等外部资源打包进去，导致worker机器在渲染的时候找不到vdb资源从而渲染缺失这些体积云图像内容，尽管blender自动打包这些资源，还是有很多熟悉BlendStation用户通过把对应volumes目录的资源手工提前放到worker机器上进行渲染，这种手工拷贝方式确实是可以解决体积渲染问题，面对用户很多体积云的渲染需求，BlendStation已经在未来版本中规划支持体积外部资源同步到worker机器上的功能，急需体积云渲染的用户短期可以使用手工同步体积云资源，不过需要注意以下事项，否则可能会遇到一些失败现象<br><br>
                </p>
                <ol>
                     <li>确保blender中的vdb路径为相对路径，建议相对路径改为和blend文件在同一级<br>
                         <img decoding="async" src="@/assets/img/volume/volume_setting.png">
                     </li>
                     <li>把volumes目录放在worker机器上的data/worker/目录下面<br>
                         <img decoding="async" src="@/assets/img/volume/volume_path.png">
                     </li>
                     <li>然后在BlendStation-Manager正常添加blend文件，像其他作业一样点击渲染就可以了，渲染结果示例如下<br>
                         <img decoding="async" src="@/assets/img/volume/volume_output.png">
                     </li>
                </ol>
</template>
